import { render, staticRenderFns } from "./breadcrumbs.vue?vue&type=template&id=62d37f0c"
import script from "./breadcrumbs.vue?vue&type=script&lang=ts"
export * from "./breadcrumbs.vue?vue&type=script&lang=ts"
import style0 from "./breadcrumbs.vue?vue&type=style&index=0&id=62d37f0c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.97.1__lodash@4.17.21__nkjjb5puplgtn2g67cuu4wxlfy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports