import { render, staticRenderFns } from "./manufacturingSeries.vue?vue&type=template&id=022fce8e"
import script from "./manufacturingSeries.vue?vue&type=script&lang=ts"
export * from "./manufacturingSeries.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.97.1__lodash@4.17.21__nkjjb5puplgtn2g67cuu4wxlfy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports